<template>
    <div class="course">
        <div class="top-head">
            <div class="return" @click="$router.go(-1)"><img src="@/assets/images/return.png"></div>
            <div class="text-center">{{$route.query.name}}</div>
        </div>
        <ul class="tab">
            <li :class="{on:tabIndex==3}" @click="changeTab(3)">全部课程</li>
            <li :class="{on:tabIndex==2}" @click="changeTab(2)">免费课程</li>
            <li :class="{on:tabIndex==1}" @click="changeTab(1)">付费课程</li>
        </ul>
        <ul class="course-list courselist" v-if="!noData">
            <li v-for="item in courseData" @click="goToDetail(item)" :key="item.id">
                <img :src="domainUrl+item.photo" alt="">
                <div class="info">
                    <div class="title ell">{{item.class_name}}</div>
                    <div class="text ell">
                        <span>{{item.count}}讲</span>
                        {{item.feature}}
                    </div>
                    <div class="detail">
                        <span class="fr extend" v-on:click.stop="share(item)">推广<img src="@/assets/images/course/extend.png" alt=""></span>
                        <em class="free" v-if="item.class_type==2">免费</em>
                        <em class="buy" v-else-if="item.buy==0">付费</em>
                        <em class="bought" v-else>付费</em>
                        <div class="price" v-if="item.buy==1&&item.class_type!=2">已购买</div>                        
                        <div class="price" v-else-if="item.class_type!=2">¥{{item.money}}</div>
                        <div class="num" v-if="item.class_type!=2">{{item.order}}人购买</div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="commment-none" v-else><img src="@/assets/images/none.png"><br>暂无内容</div>
        <div class="loading" v-show="loading"></div>
        <!-- 推广 -->
        <share v-if="shareShow" :data="data" @close="shareShow=false"></share>
    </div>
</template>
<script>
import Vue from "vue";
import share from "../common/share";
export default {
    components: { share },
    data() {
        return {
            shareShow: false,
            data: {},
            courseData: [],
            tabIndex: this.$route.query.tabIndex ? this.$route.query.tabIndex : 3,
            id: this.$route.query.id,
            uid: this.$route.query.uid,
            name: this.$route.query.name,
            from: this.$route.query.from,  //1用户端 2阿姨端
            code: this.$route.query.code ? this.$route.query.code : '',
            remainTime: "",
            isEnd: false,
            index: 1,
            size: 15,
            type: '',
            noData: false,
            loading: false
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        share(item) {
            if (this.from == 2) {
                this.data = item;
                this.shareShow = true
            } else if (this.from == 1) {
                var u = navigator.userAgent;
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                if (isiOS) {
                    window.webkit.messageHandlers.Share.postMessage({ "nickName": this.name, "courseName": item.class_name, "courseImage": this.domainUrl + item.photo, "courseDescription": item.share_desc ? item.share_desc : item.feature, "sharedQRCodeUrl": "http://school.hailuoguniang.com/#/course/login?parent_id=" + item.id, });
                } else if (isAndroid) {
                    SharedUtils.showShared(this.name, item.class_name, this.domainUrl + item.photo, item.share_desc ? item.share_desc : item.feature, "http://school.hailuoguniang.com/#/course/login?parent_id=" + item.id);
                }
            }

        },
        getNextPage() {
            let a = this.$refs.courseCon.scrollHeight;
            let b = this.$refs.courseCon.clientHeight;
            let c = this.$refs.courseCon.scrollTop;
            if (b + c >= a - 80 && this.require && !this.isLast) {
                this.index++
                this.getList();
            }
        },
        showEnd(index) {
            this.curIndex = index;
            this.curItem = this.orderData[index];
            this.hasMoney = 0;
            this.moneyNum = "";
            this.endShow = true;
        },
        countDown() {
            setInterval(() => {
                for (var key in this.courseData) {
                    if (this.courseData[key].class_type == 3 && this.courseData[key].on_line == 0) {
                        this.courseData[key].remainTime1 = '';
                        var endtime = parseInt(new Date(this.courseData[key]["end_time"].replace(/-/g, "/")).getTime());
                        var nowtime = new Date().getTime();
                        var lefttime = endtime - nowtime;
                        if (lefttime > 0) {
                            var dd = Math.floor(lefttime / 1000 / 60 / 60 / 24);
                            var hh = Math.floor((lefttime / 1000 / 60 / 60) % 24);
                            var mm = Math.floor((lefttime / 1000 / 60) % 60);
                            var ss = Math.floor((lefttime / 1000) % 60);
                            this.courseData[key]["remainTime"] = dd + "天 " + this.addZero(hh) + ":" + this.addZero(mm) + ":" + this.addZero(ss);
                        } else {
                            this.courseData[key]["remainTime"] = '';
                            this.courseData[key]["on_line"] = 1;
                            return
                        }

                    }
                }
            }, 1000);
        },
        addZero(i) {
            return i < 10 ? "0" + i : i + "";
        },
        changeTab(tab) {
            this.tabIndex = tab;
            this.index = 1;
            this.isLast = false;
            this.courseData = [];
            this.getList();
        },
        goToDetail(item) {
            this.$router.push({
                path: '/course/find/detail',
                query: {
                    id: item.id
                }
            })
        },
        getList() {
            var self = this;
            this.noData = false;
            this.loading = true;
            this.$ajax.send({
                url: "/course/getVideoByType",
                data: {
                    type_id: this.id,
                    uid: Vue.getUser().uid,
                    from: this.from,
                    page: this.index,
                    class_type: this.tabIndex == 3 ? '' : this.tabIndex,
                },
                type: "post",
                success: data => {
                    if (!!data && data.code == "1001") {
                        if (data.data.length > 0) {
                            var myNewData = []
                            data.data.map(((item, index) => {
                                myNewData.push(item)
                                if (item.class_type == 3 && item.on_line == 0) {
                                    var endtime = parseInt(new Date(item.end_time.replace(/-/g, "/")).getTime());
                                    var nowtime = new Date().getTime();
                                    var lefttime = endtime - nowtime;
                                    var dd = Math.floor(lefttime / 1000 / 60 / 60 / 24);
                                    var hh = Math.floor((lefttime / 1000 / 60 / 60) % 24);
                                    var mm = Math.floor((lefttime / 1000 / 60) % 60);
                                    var ss = Math.floor((lefttime / 1000) % 60);
                                    item.remainTime1 = dd + "天 " + this.addZero(hh) + ":" + this.addZero(mm) + ":" + this.addZero(ss);
                                }
                                self.$set(item, "remainTime", self.countDown())
                            }))
                            self.courseData = myNewData;
                            this.loading = false;
                        } else {
                            this.noData = true;
                            this.loading = false;
                        }

                    }
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.extend {
    img {
        width: 30px;
        height: 30px;
        border: none;
        vertical-align: middle;
        margin-left: 3px;
    }
}
.course {
    .tab {
        position: fixed;
        top: 88px;
        left: 0;
        width: 100%;
        height: 98px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;

        li {
            flex-grow: 1;
            line-height: 98px;
            text-align: center;
            font-size: 30px;
            color: #959595;
            position: relative;

            &.on {
                color: #353535;

                &::before {
                    content: "";
                    position: absolute;
                    width: 56px;
                    height: 4px;
                    background: #d51f13;
                    left: calc(50% - 28px);
                    bottom: 0;
                }
            }
        }
    }
    .loading {
        padding: 32px;
        position: absolute;
        top: 186px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        z-index: 10;
        background: #fff;
    }
    .courselist {
        position: absolute;
        top: 186px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
    }
    .list {
        padding: 32px;
        position: absolute;
        top: 186px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;

        &.hasNav {
            padding-bottom: 88px;
        }
        li {
            margin-bottom: 40px;
            position: relative;

            .pic {
                width: 100%;
                height: 424px;
                border-radius: 10px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 424px;
                    object-fit: cover;
                }
            }

            .info {
                height: 96px;
                position: absolute;
                top: 328px;
                left: 0;
                right: 0;
                background: rgba($color: #000000, $alpha: 0.4);
                display: block;
                padding: 0 34px;
                border-radius: 0 0 10px 10px;
                color: #fff;
                font-size: 28px;
                line-height: 96px;

                em {
                    color: #fff;
                    font-size: 30px;
                    line-height: 96px;
                    font-style: normal;
                }

                span {
                    float: right;

                    button {
                        background: #d7d7d7;
                        border-radius: 4px;
                        height: 48px;
                        line-height: 44px;
                        text-align: center;
                        color: #fff;
                        font-size: 28px;
                        border: none;
                        margin-top: 22px;
                        margin-right: 20px;
                        padding: 0 14px;

                        &.free {
                            background: #f5c604;
                        }

                        &.buy {
                            background: #ea488a;
                        }

                        &.time-free {
                            background: #ea5442;
                        }
                    }
                }
            }

            .title {
                margin-top: 24px;
                font-size: 30px;
                color: #282d3b;

                span {
                    font-size: 28px;
                    color: #959595;
                    float: right;
                }
            }
        }
    }
}

.commment-none {
    padding: 280px 20px 0 20px;
    text-align: center;
    font-size: 28px;
    color: #959595;

    img {
        width: 70%;
    }
}
.nav {
    height: 98px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    .menu {
        height: 98px;
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            flex-grow: 1;
            text-align: center;
            font-size: 22px;
            color: #656565;
            line-height: 30px;
            img {
                width: 42px;
                &.imgOn {
                    display: none;
                }
            }
            .on {
                color: #d51f13;
                img {
                    display: none;
                    &.imgOn {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
</style>